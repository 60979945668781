import React, { useState, useEffect, useRef } from "react";
import style from "./header.module.scss";
import logoBlack from "../../assets/images/black-logo.svg";
import logoWhite from "../../assets/images/white-logo.svg";
import { Button } from "../button";
import { Text } from "../text";
import { BurgerMenu } from "../burger-menu";
import { headerItems } from "./constants";

export const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [activeLink, setActiveLink] = useState(1);
  const headerRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (headerRef.current) {
        const headerHeight = headerRef.current.offsetHeight;
        const scrollPosition = window.scrollY;
        if (scrollPosition > headerHeight && !isScrolled) {
          setIsScrolled(true);
        } else if (scrollPosition <= headerHeight) {
          setIsScrolled(false);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleChangeScroll = (id) => {
    setActiveLink(id);
    let scrollElementId = headerItems.find((item) => item.id === id)?.link;
    let element = document.getElementById(`${scrollElementId}`);
    if (element) {
      return element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleChangeScrollButton = (link) => {
    let element = document.getElementById(`${link}`);
    if (element) {
      return element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <header
      ref={headerRef}
      className={
        isScrolled ? style["header-content-white"] : style["header-content"]
      }
    >
      <div className={style["header-container"]}>
        <div className={style["header-flex-wrapper"]}>
          <a href="/" className={style["logo-link"]}>
            <img className={style["logo-white"]} src={logoWhite} alt="logo" />
            <img className={style["logo-black"]} src={logoBlack} alt="logo" />
          </a>
          <div className={style["header-links"]}>
            {headerItems.map((item) => {
              return (
                <span
                  onClick={() => {
                    handleChangeScroll(item.id);
                  }}
                  key={item.id}
                  className={
                    activeLink === item.id
                      ? style["header-links-active-item"]
                      : style["header-links-item"]
                  }
                >
                  <Text color="black" type="small">
                    {item.name}
                  </Text>
                </span>
              );
            })}
          </div>
          <Button
            className={style["header-button"]}
            onClick={() => {
              handleChangeScrollButton("contact-form");
            }}
            type="yellow"
            text="Связаться с нами"
          />
          <BurgerMenu
            onChangeScroll={handleChangeScroll}
            onChangeButton={handleChangeScrollButton}
          />
        </div>
      </div>
    </header>
  );
};
