import React from "react";
import style from "./form-input.module.scss";
import { Text } from "../text";

export const FormInput = ({ label, type, name, value, onChange, required, error }) => {
    return (
        <div className={style["form-group"]}>
            <input
                id={name}
                type={type}
                name={name}
                value={value}
                onChange={onChange}
                required={required}
            />
            <div className={style["input-border"]}
                style={{
                    backgroundColor: error ? 'red' : ''
                }}></div>
            <label htmlFor={name} style={{
                    display: value ? 'none' : 'block'
                }}>{<Text type="large" color="silverChalice">{label}</Text>} {required ? <Text type="large" color="pacificBlue">*</Text> : ''}</label>
        </div>
    );
};