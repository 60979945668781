import React from "react";
import style from "./footer.module.scss";
import blackLogo from "../../assets/images/black-logo.svg";
import { Text } from "../text";

export const Footer = () => {
  return (
    <footer className={style["footer-container"]}>
      <div className={style["footer-content"]}>
        <div className={style["footer-flex-wrapper"]}>
          <div className={style["footer-information-block"]}>
            <div className={style["footer-contact-block"]}>
              <img
                src={blackLogo}
                alt="black-logo"
                className={style["black-logo"]}
              />
              <div className={style["contact-text-block"]}>
                <Text type="small" color="black">
                  ООО «Елоуби»
                </Text>
                <Text type="small" color="black">
                  Республика Беларусь, 220036
                </Text>
                <Text type="small" color="black">
                  г. Минск, ул. К. Либкнехта, д. 66, оф. 186-5
                </Text>
              </div>
            </div>
            <div className={style["footer-description-block"]}>
              <Text type="small" color="black">
                Мы и наши партнеры храним файлы cookie и/или получаем к ним
                доступ на вашем устройстве, чтобы предоставлять, измерять,
                анализировать и улучшать наши услуги.
              </Text>
              <Text type="small" color="black">
                Для получения дополнительной информации или изменения своих
                предпочтений в любое время перейдите к Политике использования
                файлов cookie.
              </Text>
              <Text type="small" color="black">
                <span>Для управления настройками файлов cookie</span>
                <a className={style.link} href="#">
                  нажмите здесь
                </a>
              </Text>
            </div>
          </div>
          <div className={style["footer-information-block"]}>
            <div className={style["info-compony"]}>
              <Text type="small" color="black">
                Ⓒ Copyright 2024
              </Text>
            </div>
            <div className={style["links-block"]}>
              <a href="mailto:info@yelowbe.by" className={style["footer-link"]}>
                <Text type="small" color="pacificBlue">
                  info@yelowbe.by
                </Text>
              </a>
              <a href="#" className={style["footer-link"]}>
                <Text type="small" color="pacificBlue">
                  Политика конфиденциальности
                </Text>
              </a>
              <a href="#" className={style["footer-link"]}>
                <Text type="small" color="pacificBlue">
                  Правила и условия использования веб-сайта
                </Text>
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
