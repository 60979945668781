import React, { useState } from "react";
import style from "./it-services.module.scss";
import { Text } from "../../components/text";
import servicesImage1 from "../../assets/images/services-image_1.svg";
import servicesImage2 from "../../assets/images/services-image_2.svg";
import servicesImage3 from "../../assets/images/services-image_3.svg";
import servicesImage4 from "../../assets/images/services-image_4.svg";
import servicesImage5 from "../../assets/images/services-image_5.svg";
import servicesImage6 from "../../assets/images/services-image_6.svg";
import plusIcon from "../../assets/images/plus-icon.svg";

const blockItems = [
  {
    id: 1,
    image: servicesImage1,
    title: "Заказная разработка",
    text: (
      <span>
        Обеспечиваем полный цикл разработки ПО: от концепции и дизайна
        пользовательского интерфейса до программирования и тестирования.
        Применяем персонализированный подход к разработке программных продуктов,
        ориентируясь на решение конкретных бизнес-задач. <br /> Основные
        направления: <br /> Веб-разработка (Frontend, Backend, DevOps). <br />{" "}
        Разработка мобильных приложений. <br /> Миграция и совершенствование
        существующих информационных систем. <br /> Реализация портальных решений
        и бизнес-приложений на заказ на платформах SimpleOne, Elma365, BPMSoft и
        EvaTeam
      </span>
    ),
  },
  {
    id: 2,
    image: servicesImage2,
    title: "Автоматизация бизнес-процессов и консалтинг",
    text: "Консультируем и помогаем предприятиям автоматизировать бизнес-процессы с учетом их потребностей. Проводим аудит, проектирование, внедрение, миграции (включая импортозамещение), а также интеграции с существующими системами. Используем современные методологии и практики, опираясь на накопленный профессионализм, знания и опыт.",
  },
  {
    id: 3,
    image: servicesImage3,
    title: "Техническая поддержка",
    text: "Предоставляем техническую поддержку в области заказной разработки и платформенных решений. Гарантируем качественное выполнение запросов на изменение (ЗНИ) и обслуживание (ЗНО).",
  },
  {
    id: 4,
    image: servicesImage4,
    title: "Бизнес-анализ",
    text: "Предоставляем услуги по бизнес-анализу, направленны на выявление, документирование, оптимизацию бизнес-процессов и требований наших клиентов. Наш подход основывается на тесном сотрудничестве с заказчиками для более глубокого понимания уникальных особенностей их бизнеса и предложения индивидуальных решений, способствующих достижению стратегических целей.",
  },
  {
    id: 5,
    image: servicesImage5,
    title: "Дизайн",
    text: "Помогаем создавать привлекательный, функциональный и адаптивный дизайн для веб-сайтов и мобильных приложений.",
  },
  {
    id: 6,
    image: servicesImage6,
    title: "Тестирование ПО",
    text: "Оказываем услуги по ручному и автоматизированному тестированию на всех стадиях разработки, обеспечивая высокое качество и надежность работы тестируемой системы.",
  },
];

export const ItServices = () => {
  const [openBlock, setOpenBlock] = useState(1);

  return (
    <section id="it-services" className={style["container"]}>
      <div className={style["container-content"]}>
        <div className={style["title-block"]}>
          <Text type="title" color="black">
            Мы предоставляем
          </Text>
          <Text type="title" color="black">
            <span className={style["title-white"]}>IT</span>-услуги
          </Text>
        </div>
        <div className={style["drop-services-box"]}>
          {blockItems.map((item) => {
            return (
              <div className={style["dropdown-container"]} key={item.id}>
                <div
                  onClick={() => {
                    setOpenBlock(item.id);
                  }}
                  className={
                    item.id === openBlock
                      ? style["item-content-hide"]
                      : style["item-content"]
                  }
                >
                  <button className={style["plus-button"]}>
                    <img alt="plus-icon" src={plusIcon} />
                  </button>
                  <Text type="subtitle" color="black">
                    {item.title}
                  </Text>
                </div>
                <div
                  className={
                    item.id === openBlock
                      ? style["info-container-active"]
                      : style["info-container"]
                  }
                >
                  <div className={style["text-content"]}>
                    <Text type="subtitle" color="black">
                      {item.title}
                    </Text>
                    <Text type="small" color="black">
                      {item.text}
                    </Text>
                  </div>
                  <img src={item.image} className={style["image-content"]} />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};
