import React from "react";
import style from "./modern-it-solutions.module.scss";
import { Text } from "../../components/text";
import solutionImage1440 from "../../assets/images/solution-1440.jpg";
import solutionImage1920 from "../../assets/images/solution-1920.jpg";
import solutionImage768 from "../../assets/images/solution-768.jpg";
import solutionImage320 from "../../assets/images/solution-320.jpg";
import { useImageByWindow } from "../../hooks/useImageByWindow";

const dataImages = [
  solutionImage1920,
  solutionImage1440,
  solutionImage768,
  solutionImage320,
];

export const ModernIRSolutions = () => {
  const { image } = useImageByWindow(dataImages);

  return (
    <section className={style["solutions-block"]}>
      <div className={style['blur-block']}></div>
      <img
        src={image}
        className={style["image-solution"]}
        alt="image-solution"
      />
      <div className={style["solution-text-block"]}>
        <Text type="title" color="white">
          Современные{" "}
        </Text>
        <Text type="title" color="citrine">
          <span class={style["blue-text"]}>IT</span> - Решения
        </Text>
      </div>
    </section>
  );
};
