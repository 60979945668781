import React from "react";
import { HexagonFilled } from "../svg/hexagon-filled-bg";
import { HexagonBorder } from "../svg/heaxagon-border-bg";
import style from "./hexagon.module.scss";
import { Text } from "../text";
import { HoneycombIcon } from "../svg/honeycomb-icon";

export const HexagonComponent = ({
  variant = "filled",
  isMirrored = false,
  honeycombCount = 1,
  honeycombText = "",
  opacity = false,
}) => {
  const renderFilledHexagon = () => (
    <div className={style["hexagon-container"]}>
      <HexagonFilled className={style["hexagon-background"]} />
      <div className={style["hexagon-content"]}>
        <div
          style={{ opacity: opacity ? "1" : "0" }}
          className={style["hexagon-honeycombs"]}
          id={`hexagon-honeycombs-${honeycombCount}`}
        >
          {Array(honeycombCount)
            .fill(null)
            .map((_, index) => (
              <HoneycombIcon key={index} />
            ))}
        </div>
        <div className={style["hexagon-text"]}>
          <Text type="small" color="black">
            <span>{honeycombText}</span>
          </Text>
        </div>
      </div>
    </div>
  );

  const renderBorderHexagon = () => (
    <div className={style["hexagon-container"]}>
      <HexagonBorder
        isMirrored={isMirrored}
        className={style["hexagon-background"]}
      />
    </div>
  );

  switch (variant) {
    case "filled":
      return renderFilledHexagon();
    case "border":
      return renderBorderHexagon();
    default:
      return null;
  }
};
