import React from "react";

export const PlusIcon = () => {
    return (
        <svg width="54" height="53" viewBox="0 0 54 53" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 26.5L51.9999 26.5111" stroke="#1D1D1D" stroke-width="3" stroke-linecap="round" />
            <path d="M27.0432 1.5L26.9999 51.5" stroke="#1D1D1D" stroke-width="3" stroke-linecap="round" />
        </svg>
    );
};
