import React, { useState, useRef } from "react";
import style from "./contact-form.module.scss";
import { Text } from "../../components/text";
import { FormInput } from "../../components/form-input";
import { Button } from "../../components/button";
import closeIcon from "../../assets/images/close.svg";

export const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    company: "",
    phone: "",
    email: "",
    message: "",
    agreement: false,
  });

  const [files, setFiles] = useState([]);
  const [errors, setErrors] = useState({});
  const fileInputRef = useRef(null);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event?.target?.files || []);

    if (selectedFiles.length + files.length > 4) {
      alert("Вы можете прикрепить максимум 4 файла.");
      return;
    }

    setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
    event.target.value = "";
  };

  const handleRemoveFile = (fileToRemove) => {
    setFiles(files.filter((file) => file !== fileToRemove));
    if (files.length === 1) {
      fileInputRef.current.value = "";
    }
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = "Имя обязательно";
    if (!formData.email) {
      newErrors.email = "Электронная почта обязательна";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Электронная почта некорректна";
    }
    if (!formData.phone) newErrors.phone = "Контактный номер обязателен";
    if (!formData.agreement)
      newErrors.agreement = "Необходимо согласие на обработку данных";
    return newErrors;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length === 0) {
      const sendForm = new FormData();
      sendForm.append("your-name", formData.name);
      sendForm.append("company", formData.company);
      sendForm.append("phone", formData.phone);
      sendForm.append("email", formData.email);
      sendForm.append("message", formData.message);
      sendForm.append("agreement", formData.agreement);
      sendForm.append("_wpcf7_unit_tag", "data.form_id");
      files.forEach((file) => {
        sendForm.append("attachments[]", file);
      });
      try {
        const response = await fetch(
          "/wp-json/contact-form-7/v1/contact-forms/8/feedback",
          {
            method: "POST",
            body: sendForm,
          }
        );

        if (!response.ok) {
          const errorData = await response.json();
          console.error("Error:", errorData.errors);
          setErrors(errorData.errors);
        } else {
          setFormData({
            name: "",
            company: "",
            phone: "",
            email: "",
            message: "",
            agreement: false,
          });
          alert("Форма отправлена!");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    } else {
      setErrors(validationErrors);
    }
  };

  return (
    <div id="contact-form" className={style["contact-form-block"]}>
      <div className={style["contact-form-title"]}>
        <Text type="title" color="black">
          Как
        </Text>
        <Text type="title" color="black">
          Cвязаться
        </Text>
        <Text type="title" color="barberry">
          C нами
        </Text>
      </div>
      <div className={style["contact-form-container"]}>
        <div className={style["contact-form-row"]}>
          <FormInput
            label="Введите своё имя"
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            error={errors.name}
            required
          />
          <FormInput
            label="Название компании"
            type="text"
            name="company"
            value={formData.company}
            onChange={handleChange}
          />
        </div>
        <div className={style["contact-form-row"]}>
          <FormInput
            label="Контактный номер"
            type="tel"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            error={errors.phone}
            required
          />
          <FormInput
            label="Электронная почта"
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            error={errors.email}
            required
          />
        </div>
        <div className={style["contact-form-row"]}>
          <FormInput
            label="Напишите текст вашего обращения"
            type="text"
            name="message"
            value={formData.message}
            onChange={handleChange}
          />
        </div>
        <div className={`${style["contact-form-row"]} ${style["send-row"]}`}>
          <Button
            className={style["send-button"]}
            text="Отправить сообщение"
            type="yellow"
            onClick={handleSubmit}
          />
          <div className={style["file-input-container"]}>
            <input
              type="file"
              multiple
              onChange={handleFileChange}
              style={{ display: "none" }}
              id="file-upload"
              ref={fileInputRef}
            />
            <label htmlFor="file-upload" className={style["file-upload-label"]}>
              Прикрепить файл
            </label>
          </div>
        </div>
        <div className={style["contact-form-row"]}>
          <div className={style["agree-row"]}>
            <input
              type="checkbox"
              id="agreement"
              name="agreement"
              checked={formData.agreement}
              onChange={handleChange}
              required
              className={errors.agreement ? style["checkbox-error"] : ""}
            />
            <label htmlFor="agree">
              Согласие на обработку персональных данных
            </label>
          </div>
        </div>
        <ul className={style["files-list"]}>
          {files.map((file, index) => (
            <li key={index}>
              {file.name}{" "}
              <button type="button" onClick={() => handleRemoveFile(file)}>
                <img
                  className={style["close-icon"]}
                  src={closeIcon}
                  alt="close"
                />
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
