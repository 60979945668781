import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import Error from './Error';

import {
	createBrowserRouter,
	RouterProvider,
  } from "react-router-dom";


  const router = createBrowserRouter([
	{
	  path: "/",
	  element: < App />,
	  errorElement: <Error />,
	}
  ]);

const el = document.getElementById( 'my-app' );

if ( el ) {
	ReactDOM.createRoot( el ).render(
		<React.StrictMode>
			<RouterProvider router={router} />
		</React.StrictMode>,
	);
}

