import React from "react";
import style from "./our-partners.module.scss";
import { Text } from "../../components/text";
import partnerIcon from "../../assets/images/partners-full.svg";
import borderIcon from "../../assets/images/partners-border.svg";
import evaIcon from "../../assets/images/eva.svg";
import simpleIcon from "../../assets/images/simple.svg";
import bpmIcon from "../../assets/images/bpm.svg";
import elmaIcon from "../../assets/images/elma.svg";
import sferaIcon from "../../assets/images/sfera.svg";

export const OurPartners = () => {
  return (
    <section id="our-partners" className={style["partners-wrapper"]}>
      <div className={style["partners-block"]}>
        <div className={style["partners-title"]}>
          <Text type="title" color="gravel">
            Наши
          </Text>
          <Text type="title" color="barberry">
            Партнеры
          </Text>
        </div>
        <div className={style["hexagon-item-1"]}>
          <img className={style["border"]} src={borderIcon} />
        </div>
        <div className={style["hexagon-item-eva"]}>
          <img className={style["fill"]} src={partnerIcon} />
          <img className={style["logo"]} src={evaIcon} />
        </div>
        <div className={style["hexagon-item-simple"]}>
          <img className={style["fill"]} src={partnerIcon} />
          <img className={style["logo"]} src={simpleIcon} />
        </div>
        <div className={style["hexagon-item-2"]}>
          <img className={style["border"]} src={borderIcon} />
        </div>
        <div className={style["hexagon-item-bpm"]}>
          <img className={style["fill"]} src={partnerIcon} />
          <img className={style["logo"]} src={bpmIcon} />
        </div>
        <div className={style["hexagon-item-elma"]}>
          <img className={style["fill"]} src={partnerIcon} />
          <img className={style["logo"]} src={elmaIcon} />
        </div>
        <div className={style["hexagon-item-3"]}>
          <img className={style["border"]} src={borderIcon} />
        </div>
        <div className={style["hexagon-item-sfera"]}>
          <img className={style["fill"]} src={partnerIcon} />
          <img className={style["logo"]} src={sferaIcon} />
        </div>
        <div className={style["hexagon-item-4"]}>
          <img className={style["border"]} src={borderIcon} />
        </div>
      </div>
    </section>
  );
};
