import style from "./global/app.module.scss";
import { NotFoundPage } from './pages/not-found-page';

function App() {
  return (
    <>
      <main className={style["main-layout"]}>
        <div className={style.content}>
          <NotFoundPage />
        </div>
      </main>
    </>
  );
}

export default App;