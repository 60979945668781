import React from 'react';
import { PlusIcon } from '../svg/plus-icon';
import { MinusIcon } from '../svg/minus-icon';
import style from './solutions-item.module.scss';
import { Text } from '../text';

export const SolutionItem = ({ title, img, text, isOpen, onClick }) => {
    return (
        <div className={style['solution-item']}>
            <button
                className={`${style['solution-item-title']} ${isOpen ? style['title-open'] : style['title-close']}`}
                onClick={onClick}
            >
                <Text type="subtitle" color={isOpen ? 'white' : 'black'}>{title}</Text>
                <div>{isOpen ? <MinusIcon /> : <PlusIcon />}</div>
            </button>
            <div className={`${style['solution-item-content']} ${isOpen ? style['open'] : style['close']}`}>
                <img className={style['solution-item-img']} src={img} alt="img" />
                <Text type="small" color="black">{text}</Text>
            </div>
        </div>
    );
};
