import React from "react";
import style from "./button.module.scss";

const typeButton = {
  yellow: "yellow-button",
  white: "white-button",
};

export const Button = ({ type = "yellow", onClick, text = "", className }) => {
  return (
    <button
      onClick={onClick}
      className={`${style.button} ${style[typeButton[type]]} ${
        className ? className : ""
      }`}
    >
      {text}
    </button>
  );
};
