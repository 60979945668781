import React, { useEffect, useState, useRef } from "react";
import style from "./why-choose-us.module.scss";
import { Text } from "../../components/text";
import { ChooseItem } from "../../components/choose-item/";
import { useImageByWindow } from "../../hooks/useImageByWindow.js";
import chooseImage1440 from "../../assets/images/choose-1440.jpg";
import chooseImage1920 from "../../assets/images/choose-1920.jpg";
import chooseImage768 from "../../assets/images/choose-768.jpg";
import chooseImage320 from "../../assets/images/choose-320.jpg";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";

const whyChooseItems = [
  {
    number: 1,
    alterNumberBorder: false,
    titleText: "Гибкость и адаптивность",
    contentText:
      "Мы быстро реагируем на изменения на рынке и требований заказчиков.",
  },
  {
    number: 2,
    alterNumberBorder: true,
    titleText: "Инновации и качество",
    contentText:
      "Мы используем решения, которые отвечают потребностям и ожиданиям клиентов.",
  },
  {
    number: 3,
    alterNumberBorder: true,
    titleText: "Долгосрочные отношения",
    contentText:
      "Мы выстраиваем доверительные отношения с клиентами, партнерами, обеспечивая высокий уровень сервиса и поддержки.",
  },
  {
    number: 4,
    alterNumberBorder: false,
    titleText: "Компетенции и мотивация",
    contentText:
      "Наш персонал постоянно совершенствует свои навыки и знания, используя современные технологии и методологии.",
  },
];

const imageData = [
  chooseImage1920,
  chooseImage1440,
  chooseImage768,
  chooseImage320,
];

gsap.registerPlugin(useGSAP);
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(MotionPathPlugin);

export const WhyChooseUs = () => {
  const chooseBlockElement = useRef(null);
  const chooseItemElements = useRef([]);
  const tl = useRef();
  const { image } = useImageByWindow(imageData);

  useGSAP(
    () => {
      tl.current = gsap
        .timeline({
          scrollTrigger: {
            trigger: "#choose-text-block",
            start: "top center",
            end: "+=100",
          },
        })
        .fromTo(
          "#choose-item-1",
          {
            opacity: 0,
            y: 50,
          },
          {
            opacity: 1,
            y: 0,
            duration: 0.5,
          }
        )
        .fromTo(
          "#choose-item-2",
          {
            opacity: 0,
            y: 50,
          },
          {
            opacity: 1,
            y: 0,
            duration: 0.5,
          }
        )
        .fromTo(
          "#choose-item-3",
          {
            opacity: 0,
            y: 50,
          },
          {
            opacity: 1,
            y: 0,
            duration: 0.5,
          }
        )
        .fromTo(
          "#choose-item-4",
          {
            opacity: 0,
            y: 50,
          },
          {
            opacity: 1,
            y: 0,
            duration: 0.5,
          }
        );
    },
    { scope: chooseBlockElement }
  );

  return (
    <section ref={chooseBlockElement} className={style["choose-block"]}>
      <img src={image} className={style["image-choose"]} alt="image-choose" />
      <div className={style["gradient-overlay"]}></div>
      <div id="choose-text-block" className={style["choose-text-block"]}>
        <Text type="title" color="white">
          Почему{" "}
        </Text>
        <Text type="title" color="white">
          Выбирают <span className={style["yellow-text"]}>Нас</span>
        </Text>
      </div>
      {whyChooseItems.map((item, index) => (
        <div
          key={index}
          ref={(element) => (chooseItemElements.current[index] = element)}
          className={style["choose-block-item"]}
          id={`choose-item-${item.number}`}
        >
          <ChooseItem
            number={item.number}
            alterNumberBorder={item.alterNumberBorder}
            titleText={item.titleText}
            contentText={item.contentText}
          />
        </div>
      ))}
    </section>
  );
};
