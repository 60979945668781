import React, { useRef } from "react";
import { Text } from "../../components/text";
import { HexagonComponent } from "../../components/hexagon";
import style from "./dev-process.module.scss";
import beeIcon from "../../assets/images/bee.svg";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";

gsap.registerPlugin(useGSAP);
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(MotionPathPlugin);

export const DevProcess = () => {
  const beeRef = useRef();
  const test1 = useRef();
  const test2 = useRef();
  const test3 = useRef();
  const test4 = useRef();
  const test5 = useRef();
  const test6 = useRef();
  const sectionRef = useRef(null);
  const tl = useRef();

  useGSAP(
    () => {
      const updatePositions = () => {
        return [
          {
            x: test1.current.getBoundingClientRect().left,
            y: test1.current.getBoundingClientRect().top,
          },
          {
            x: test2.current.getBoundingClientRect().left,
            y: test2.current.getBoundingClientRect().top,
          },
          {
            x: test3.current.getBoundingClientRect().left,
            y: test3.current.getBoundingClientRect().top,
          },
          {
            x: test4.current.getBoundingClientRect().left,
            y: test4.current.getBoundingClientRect().top,
          },
          {
            x: test5.current.getBoundingClientRect().left,
            y: test5.current.getBoundingClientRect().top,
          },
          {
            x: test6.current.getBoundingClientRect().left,
            y: test6.current.getBoundingClientRect().top,
          },
        ];
      };

      let points = updatePositions();

      const observer = new MutationObserver(() => {
        points = updatePositions();
      });

      observer.observe(sectionRef.current, {
        childList: true,
        subtree: true,
        attributes: true,
      });

      tl.current = gsap
        .timeline({
          scrollTrigger: {
            trigger: "#dev-process-title",
            start: "top center",
            end: "+=400",
          },
        })
        .to("#bee-container", {
          duration: 1.5,
          x: points[0].x,
          y:
            points[0].y -
            sectionRef.current.getBoundingClientRect().top -
            beeRef.current.offsetTop,
          ease: "power1.inOut",
        })
        .to("#bee-container", {
          duration: 2,
          x: points[1].x,
          y:
            points[1].y -
            sectionRef.current.getBoundingClientRect().top -
            beeRef.current.offsetTop,
          ease: "power1.inOut",
          onStart: () => {
            gsap.to("#hexagon-honeycombs-1", {
              opacity: 1,
              duration: 1,
              ease: "power1.inOut",
            });
          },
        })
        .to("#bee-container", {
          duration: 2,
          x: points[2].x,
          y:
            points[2].y -
            sectionRef.current.getBoundingClientRect().top -
            beeRef.current.offsetTop,
          ease: "power1.inOut",
          onStart: () => {
            gsap.to("#hexagon-honeycombs-2", {
              opacity: 1,
              duration: 1,
              ease: "power1.inOut",
            });
          },
        })
        .to("#bee-container", {
          duration: 2,
          x: points[3].x,
          y:
            points[3].y -
            sectionRef.current.getBoundingClientRect().top -
            beeRef.current.offsetTop,
          ease: "power1.inOut",
          onStart: () => {
            gsap.to("#hexagon-honeycombs-3", {
              opacity: 1,
              duration: 1,
              ease: "power1.inOut",
            });
          },
        })
        .to("#bee-container", {
          duration: 2,
          x: points[4].x,
          y:
            points[4].y -
            sectionRef.current.getBoundingClientRect().top -
            beeRef.current.offsetTop,
          ease: "power1.inOut",
          onStart: () => {
            gsap.to("#hexagon-honeycombs-4", {
              opacity: 1,
              duration: 1,
              ease: "power1.inOut",
            });
          },
        })
        .to("#bee-container", {
          duration: 2,
          x: points[5].x,
          y:
            points[5].y -
            sectionRef.current.getBoundingClientRect().top -
            beeRef.current.offsetTop,
          ease: "power1.inOut",
          onStart: () => {
            gsap.to("#hexagon-honeycombs-5", {
              opacity: 1,
              duration: 1,
              ease: "power1.inOut",
            });
          },
        })
        .to("#bee-container", {
          duration: 2,
          x: 2000,
          y:
            points[5].y -
            sectionRef.current.getBoundingClientRect().top -
            beeRef.current.offsetTop,
          ease: "power1.inOut",
          onStart: () => {
            gsap.to("#hexagon-honeycombs-6", {
              opacity: 1,
              duration: 1,
              ease: "power1.inOut",
            });
          },
        });

      return () => observer.disconnect();
    },
    { scope: sectionRef }
  );

  return (
    <div
      id="dev-process"
      ref={sectionRef}
      className={style["dev-process-wrapper"]}
    >
      <div ref={beeRef} id="bee-container" className={style["bee-container"]}>
        <img className={style["bee-img"]} src={beeIcon} />
      </div>
      <div className={style["title-container"]}>
        <Text type="title" color="gravel">
          Процесс
        </Text>
        <Text type="title" color="barberry">
          Разработки
        </Text>
      </div>
      <div className={style["dev-process-block"]}>
        <div
          id="dev-process-title"
          className={style["dev-process-title"]}
        ></div>
        <div className={style["dev-tile-border-left-1"]}>
          <HexagonComponent variant="border" />
        </div>
        <div className={style["dev-tile-border-left-2"]}>
          <HexagonComponent variant="border" />
        </div>
        <div className={style["dev-tile-border-left-3"]}>
          <HexagonComponent variant="border" />
        </div>
        <div
          ref={test1}
          className={`${style["dev-tile-filled-1"]} ${style["second-row"]}`}
        >
          <HexagonComponent
            variant="filled"
            honeycombCount={1}
            honeycombText="Выявление возможностей"
          />
        </div>
        <div
          ref={test2}
          className={`${style["dev-tile-filled-2"]} ${style["second-row"]}`}
        >
          <HexagonComponent
            variant="filled"
            honeycombCount={2}
            honeycombText="Определение решения"
          />
        </div>
        <div
          ref={test3}
          className={`${style["dev-tile-filled-3"]} ${style["second-row"]}`}
        >
          <HexagonComponent
            variant="filled"
            honeycombCount={3}
            honeycombText="Тестирование прототипа"
          />
        </div>
        <div
          ref={test5}
          className={`${style["dev-tile-filled-4"]} ${style["second-row"]}`}
        >
          <HexagonComponent
            variant="filled"
            honeycombCount={5}
            honeycombText="Бета"
          />
        </div>
        <div ref={test4} className={style["dev-tile-filled-5"]}>
          <HexagonComponent
            variant="filled"
            honeycombCount={4}
            honeycombText="Mvp"
          />
        </div>
        <div ref={test6} className={style["dev-tile-filled-6"]}>
          <HexagonComponent
            variant="filled"
            honeycombCount={6}
            honeycombText="Масштабирование и эксплуатация"
          />
        </div>
        <div
          className={`${style["dev-tile-border-right-1"]} ${style["second-row"]}`}
        >
          <HexagonComponent variant="border" />
        </div>
        <div className={style["dev-tile-border-right-2"]}>
          <HexagonComponent variant="border" isMirrored={true} />
        </div>
        <div className={style["dev-tile-border-right-3"]}>
          <HexagonComponent variant="border" />
        </div>
      </div>
    </div>
  );
};
