import React from "react";

export const HoneycombIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="24"
      viewBox="0 0 21 24"
      fill="none"
    >
      <path
        d="M8.98446 0.418094C9.92228 -0.139365 11.0777 -0.139365 12.0155 0.418094L19.4845 4.85775C20.4223 5.41521 21 6.44543 21 7.56035V16.4397C21 17.5546 20.4223 18.5848 19.4845 19.1423L12.0155 23.5819C11.0777 24.1394 9.92228 24.1394 8.98446 23.5819L1.51554 19.1423C0.577723 18.5848 0 17.5546 0 16.4397V7.56035C0 6.44543 0.577722 5.41521 1.51554 4.85775L8.98446 0.418094Z"
        fill="#E2CF00"
      />
    </svg>
  );
};
