import React, { useState } from "react";
import { Button } from "../button";
import logoBlack from "../../assets/images/black-logo.svg";
import { BurgerIcon } from "../svg/burger-icon";
import { CloseIcon } from "../svg/close-icon";
import { Text } from "../text";
import style from "./burger-menu.module.scss";
import { headerItems } from "../header/constants";

export const BurgerMenu = ({ onChangeScroll, onChangeButton }) => {
  const [burgerOpen, setBurgerOpen] = useState(false);
  const [activeLink, setActiveLink] = useState(1);

  const toggleBurgerOpen = () => {
    setBurgerOpen(!burgerOpen);
  };

  const handleChangeScrollBurger = (id) => {
    toggleBurgerOpen();
    setActiveLink(id);
    onChangeScroll(id);
  };

  const handleChangeBurgerButton = (id) => {
    toggleBurgerOpen();
    onChangeButton(id);
  };

  return (
    <div className={style["burger-wrapper"]}>
      <button onClick={toggleBurgerOpen} className={style["burger-button"]}>
        <BurgerIcon />
      </button>
      <div
        className={`${style["burger-content"]} ${burgerOpen ? style.open : ""}`}
      >
        <button
          onClick={toggleBurgerOpen}
          className={style["close-burger-button"]}
        >
          <CloseIcon />
        </button>
        <div className={style["burger-menu-content"]}>
          <a href="/" className={style["logo-link"]}>
            <img className={style["logo-black"]} src={logoBlack} alt="logo" />
          </a>
          <div onClick={toggleBurgerOpen} className={style["burger-links"]}>
            {headerItems.map((item) => {
              return (
                <span
                  onClick={() => {
                    handleChangeScrollBurger(item.id);
                  }}
                  key={item.id}
                  className={
                    activeLink === item.id
                      ? style["burger-links-item-active"]
                      : style["burger-links-item"]
                  }
                >
                  <Text color="black" type="small">
                    {item.name}
                  </Text>
                </span>
              );
            })}
          </div>
          <Button
            className={style["burger-contact-button"]}
            onClick={() => {
              handleChangeBurgerButton("contact-form");
            }}
            type="yellow"
            text="Связаться с нами"
          />
        </div>
      </div>
    </div>
  );
};
