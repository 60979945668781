import React from "react";

export const HexagonFilled = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="284" height="320" viewBox="0 0 284 320" fill="none">
  <g filter="url(#filter0_dii_344_386)">
    <path d="M132.473 3.27221C137.75 0.242597 144.25 0.242595 149.527 3.27221L271.473 73.2922C276.75 76.3218 280 81.9208 280 87.98V228.02C280 234.079 276.75 239.678 271.473 242.708L149.527 312.728C144.25 315.757 137.75 315.757 132.473 312.728L10.5267 242.708C5.25038 239.678 2 234.079 2 228.02V87.98C2 81.9208 5.25038 76.3218 10.5267 73.2922L132.473 3.27221Z" fill="#EBEBEB"/>
  </g>
  <defs>
    <filter id="filter0_dii_344_386" x="0" y="0" width="284" height="320" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dx="1" dy="2"/>
      <feGaussianBlur stdDeviation="1.5"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_344_386"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_344_386" result="shape"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dx="1" dy="1"/>
      <feGaussianBlur stdDeviation="1"/>
      <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"/>
      <feBlend mode="normal" in2="shape" result="effect2_innerShadow_344_386"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="1"/>
      <feGaussianBlur stdDeviation="1"/>
      <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"/>
      <feBlend mode="normal" in2="effect2_innerShadow_344_386" result="effect3_innerShadow_344_386"/>
    </filter>
  </defs>
</svg>
  );
};
