import React from "react";
import style from "./text.module.scss";

const typesText = {
  title: "title-text",
  customTitle: "custom-title",
  subtitle: "subtitle-text",
  large: "large-text",
  small: "small-text",
};

const textColors = {
  white: "#FFFFFF",
  citrine: "#E2CF00",
  barberry: "#D8C500",
  pacificBlue: "#00A6B4",
  gravel: "#474A50",
  black: "#1D1D1D",
  darkMustard: "#9F9100",
  whiteSmoke: "#F5F5F5",
  gainsboro: "#DDDDDD",
  silverChalice: "#ACACAC",
  platinum: "#E3E2E2",
};

export const Text = ({
  children,
  type = "small",
  color = "black",
  className,
}) => {
  return (
    <span
      className={`${style[typesText[type]]} ${className ? className : ""}`}
      style={{ color: textColors[color] }}
    >
      {children}
    </span>
  );
};
