import React from "react";
import style from "./choose-item.module.scss";
import { Text } from "../text";
import { ChooseIcon } from "../svg/choose-icon";

export const ChooseItem = ({ number = 1, alterNumberborder = false, titleText = '', contentText = '' }) => {
    return (
        <div className={style['choose-item']}>
            <div className={style['choose-item-icon']}>
                <Text type="subtitle" color="citrine">0{number}</Text>
                <ChooseIcon alternative={alterNumberborder} />
            </div>
            <div className={style['choose-item-content']}>
                <Text type="subtitle" color="white">{titleText}</Text>
                <Text type="small" color="white">{contentText}</Text>
            </div>
        </div>
    );
};
