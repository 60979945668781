import React from "react";
import style from "./about-us.module.scss";
import { Text } from "../../components/text";
import iconAboutUs from "../../assets/images/about-us-be.svg";
import iconNoContent from "../../assets/images/about-us-no-content.svg";

export const AboutUs = () => {
  return (
    <section className={style.content}>
      <Text className={style["about-us-title"]} type="title" color="black">
        О нас
      </Text>
      <div className={style["grid-hexagon"]}>
        <div className={style["grid-hexagon_item-1"]}>
          <img className={style["img-bee"]} src={iconNoContent} />
        </div>
        <div className={style["grid-hexagon_item-2"]}>
          <img className={style["img-bee"]} src={iconAboutUs} />
          <div className={style["info-block"]}>
            <Text type="title" color="citrine">
              50+
            </Text>
            <Text type="small" color="black">
              Более 50 экспертов в области управления ESM, ITSM и CRM.
            </Text>
          </div>
        </div>
        <div className={style["grid-hexagon_item-3"]}>
          <img className={style["img-bee"]} src={iconAboutUs} />
          <div className={style["info-block"]}>
            <Text type="title" color="citrine">
              2
            </Text>
            <Text type="small" color="black">
              География нашей работы: Россия и Беларусь.
            </Text>
          </div>
        </div>
        <div className={style["grid-hexagon_item-4"]}>
          <img className={style["img-bee"]} src={iconNoContent} />
        </div>
        <div className={style["grid-hexagon_item-5"]}>
          <img className={style["img-bee"]} src={iconAboutUs} />
          <div className={style["info-block"]}>
            <Text type="title" color="citrine">
              70+
            </Text>
            <Text type="small" color="black">
              Более 70 завершённых проектов.
            </Text>
          </div>
        </div>
        <div className={style["grid-hexagon_item-6"]}>
          <img className={style["img-bee"]} src={iconAboutUs} />
          <div className={style["info-block"]}>
            <Text type="title" color="citrine">
              12+
            </Text>
            <Text type="small" color="black">
              Более 12 лет опыта в платформах класса ESM, ITSM, CRM, BI.
            </Text>
          </div>
        </div>
        <div className={style["grid-hexagon_item-7"]}>
          <img className={style["img-bee"]} src={iconNoContent} />
        </div>
      </div>
    </section>
  );
};
