import style from "./global/app.module.scss";
import { Header } from "./components/header";
import { Footer } from "./components/footer";
import { ModernIRSolutions } from "./sections/modern-it-solutions";
import { AboutUs } from "./sections/about-us";
import { WhyChooseUs } from "./sections/why-choose-us";
import { OurOffices } from "./sections/our-offices";
import { SolutionsAccordion } from "./sections/solutions-accordion";
import { ItServices } from "./sections/it-services";
import { DevProcess } from "./sections/dev-process";
import { ContactForm } from "./sections/contact-form";
import { OurPartners } from "./sections/our-partners";

function App() {
  return (
    <>
      <Header />
      <main className={style["main-layout"]}>
        <div className={style.content}>
          <ModernIRSolutions />
          <AboutUs/>
          <ItServices />
          <DevProcess />
          <WhyChooseUs />
          <SolutionsAccordion />
          <OurPartners />
          <OurOffices />
          <ContactForm />
        </div>
      </main>
      <Footer />
    </>
  );
}

export default App;
