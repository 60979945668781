import React from "react";

export const HexagonBorder = ({ isMirrored = false }) => {
    const svgContent = (
        <path
            d="M1.99999 234.5V87.423C1.99999 81.2917 5.30156 75.6354 10.6403 72.6204L132.64 3.72113C137.828 0.791187 144.172 0.791186 149.36 3.72113L210.5 38.25M141 313L271.36 239.38C276.698 236.365 280 230.708 280 224.577V156"
            stroke="#E2CF00"
            strokeWidth="3"
            strokeLinecap="round"
        />
    );

    return (
        <svg
            width="282"
            height="315"
            viewBox="0 0 282 315"
            fill="none"
        >
            {isMirrored ? (
                <path
                    d="M280 234.5V87.423C280 81.2917 276.698 75.6354 271.36 72.6204L149.36 3.72113C144.172 0.791187 137.828 0.791186 132.64 3.72113L71.5 38.25M141 313L10.6403 239.38C5.30156 236.365 2 230.708 2 224.577V156"
                    stroke="#E2CF00"
                    strokeWidth="3"
                    strokeLinecap="round"
                />
            ) : (
                svgContent
            )}
        </svg>
    );
};
