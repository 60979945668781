import React from "react";
import style from "./our-offices.module.scss";
import { Text } from "../../components/text";
import ourOffices1920 from "../../assets/images/our-offices_1920.jpg";
import ourOffices1440 from "../../assets/images/our-offices_1440.jpg";
import ourOffices768 from "../../assets/images/our-offices_768.jpg";
import ourOffices320 from "../../assets/images/our-offices_320.jpg";
import { useImageByWindow } from "../../hooks/useImageByWindow";

const dataImages = [
  ourOffices1920,
  ourOffices1440,
  ourOffices768,
  ourOffices320,
];

export const OurOffices = () => {
  const { image } = useImageByWindow(dataImages);
  return (
    <section className={style["container"]}>
      <img src={image} className={style["image-offices"]} alt="image offices" />
      <div className={style["title-block"]}>
        <Text type="title" color="white">
          Наши
        </Text>
        <Text type="title" color="citrine">
          Офисы
        </Text>
      </div>
    </section>
  );
};
