import React from "react";

export const CloseIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="58"
      height="64"
      viewBox="0 0 58 64"
      fill="none"
    >
      <g filter="url(#filter0_b_549_1462)">
        <path
          d="M27.382 1.4221C28.3822 0.8593 29.6178 0.8593 30.618 1.4221L31.1084 0.550594L30.618 1.4221L55.4012 15.3673C56.3988 15.9286 57 16.9565 57 18.0548V45.9452C57 47.0435 56.3988 48.0714 55.4012 48.6327L30.618 62.5779C29.6178 63.1407 28.3822 63.1407 27.382 62.5779L2.59878 48.6327C1.60123 48.0714 1 47.0435 1 45.9452V18.0548C1 16.9565 1.60123 15.9286 2.59878 15.3673L27.382 1.4221Z"
          stroke="#474A50"
          stroke-width="2"
        />
        <path
          d="M19.1006 22.1006L38.8996 41.8996"
          stroke="#474A50"
          stroke-width="3"
          stroke-linecap="round"
        />
        <path
          d="M19 41.7991L38.799 22.0001"
          stroke="#474A50"
          stroke-width="3"
          stroke-linecap="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_b_549_1462"
          x="-6.18182"
          y="-6.18182"
          width="70.3636"
          height="76.3636"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="3.09091" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_549_1462"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_549_1462"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
