import React, { useState } from 'react';
import { SolutionItem } from '../../components/solutions-item';
import SolutionImage1920 from '../../assets/images/solution-accordion1920.jpg';
import SolutionImage1440 from '../../assets/images/solution-accordion1440.jpg';
import SolutionImage768 from '../../assets/images/solution-accordion768.jpg';
import SolutionImage320 from '../../assets/images/solution-accordion320.jpg';
import style from './solutions-accordion.module.scss';
import { Text } from '../../components/text';
import { useImageByWindow } from "../../hooks/useImageByWindow";

const dataImages = [
  SolutionImage1920,
  SolutionImage1440,
  SolutionImage768,
  SolutionImage320,
];

const items = [
  {
    title: 'ITSM (Управление ИТ-услугами)',
    text: 'Мы предлагаем широкий спектр услуг в области управления ИТ-услугами (IT Service Management, ITSM), нацеленных на оптимизацию и повышение эффективности ИТ-операций. Команда наших специалистов обладает опытом и знаниями, необходимыми для реализации лучших практик и подходов в управлении ИТ-услугами, что позволяет бизнесам заказчиков достигать максимальной производительности и положительного эффекта от внедрения технологий.',
  },
  {
    title: 'ITOM (Оперативное управление ИТ)',
    text: 'В рамках управления ИТ-операциями (IT Operations Management, ITOM) мы предлагаем услуги, направленные на максимизацию эффективности, надежности и безопасности ИТ-инфраструктур. Наша команда экспертов помогает организациям оптимизировать процессы, снизить затраты и обеспечить бесперебойную работу ИТ-услуг.',
  },
  {
    title: 'SecOps (Операции безопасности)',
    text: 'Наши услуги в области SecOps (Security Operations) направлены на защиту ИТ-ресурсов и данных от угроз и уязвимостей. Наши эксперты по безопасности оперативно реагируют на инциденты, анализируют угрозы и разрабатывают стратегии для обеспечения эффективной защиты бизнеса.',
  },
  {
    title: 'DevOps (Разработка и эксплуатация)',
    text: 'Мы предлагаем разнообразные услуги в области DevOps, которые помогают объединить процессы разработки и операционной деятельности для достижения более быстрого, качественного и эффективного развертывания программных решений. Наши эксперт-инженеры DevOps обеспечивают интеграцию за счет автоматизации и внедрения современных технологических решений и методологий, что позволяет организациям адаптироваться к меняющимся требованиям рынка и повысить общую производительность.',
  },
  {
    title: 'CSM (Управление обслуживанием клиентов)',
    text: 'Наши услуги в области управления обслуживанием клиентов (CMS) направлены на создание эффективных, отзывчивых и персонализированных взаимодействий с клиентами организаций. Мы помогаем бизнесам автоматизировать и оптимизировать процессы обслуживания, что способствует повышению удовлетворенности клиентов и укреплению их лояльности.',
  },
];
export const SolutionsAccordion = () => {
  const { image } = useImageByWindow(dataImages);
  const [openItems, setOpenItems] = useState([]);

  const handleItemClick = (index) => {
    setOpenItems((prevOpenItems) => {
      if (prevOpenItems.includes(index)) {
        return prevOpenItems.filter(item => item !== index);
      } else {
        return [...prevOpenItems, index];
      }
    });
  };
  
  return (
    <div className={style['solutions-accordion-block']}>
      <div className={style['solutions-accordion-title']}>
        <Text type="title" color="black">Наши современные</Text>
        <Text type="title" color="black"><span className={style['white-text']}>IT</span>-решения</Text>
      </div>
      <div className={style['solutions-accordion-items']}>
        {items.map((item, index) => (
          <SolutionItem key={index} title={item.title} img={image} text={item.text} isOpen={openItems.includes(index)} onClick={() => handleItemClick(index)} />
        ))}
      </div>
    </div>
  );
};


