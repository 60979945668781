import { useState, useEffect } from "react";

export const useImageByWindow = (dataImages) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [image, setImage] = useState("");

  const getImageBySize = (width) => {
    switch (true) {
      case width >= 1441:
        return dataImages[0];
      case width >= 1281:
        return dataImages[1];
      case width >= 520:
        return dataImages[2];
      default:
        return dataImages[3];
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setImage(getImageBySize(window.innerWidth));
    };
    setImage(getImageBySize(windowWidth));
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [windowWidth]);

  return { image };
};
