import React from "react";
import style from "./not-found-page.module.scss";
import notFoundImage1920 from "../../assets/images/not-found_1920.jpg";
import notFoundImage1440 from "../../assets/images/not-found_1440.jpg";
import notFoundImage768 from "../../assets/images/not-found_768.jpg";
import notFoundImage320 from "../../assets/images/not-found_320.jpg";
import notFoundText from "../../assets/images/not-found-text.svg";
import { useImageByWindow } from "../../hooks/useImageByWindow";
import { Text } from "../../components/text";
import { Button } from "../../components/button";

const dataImages = [
  notFoundImage1920,
  notFoundImage1440,
  notFoundImage768,
  notFoundImage320,
];

export const NotFoundPage = () => {
  const { image } = useImageByWindow(dataImages);
  return (
    <section
      style={{ backgroundImage: `url(${image})` }}
      className={style["not-found-section"]}
    >
      <div className={style["blur-block"]}></div>
      <div className={style["content"]}>
        <img
          className={style["not-found-text"]}
          src={notFoundText}
          alt="not found text"
        />
        <Text type="customTitle" color="white">
          Ой!
        </Text>
        <Text type="small" color="white">
          Похоже здесь нет мёда
        </Text>
        <Button
          className={style["back-button"]}
          onClick={() => {}}
          type="yellow"
          text="Вернуться в улей"
        />
      </div>
    </section>
  );
};
