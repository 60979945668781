import React from "react";

export const ChooseIcon = ({ alternative = false }) => {
    return alternative ? (
        <svg xmlns="http://www.w3.org/2000/svg" width="134" height="134" viewBox="0 0 134 134" fill="none">
          <path d="M132 76.569L107.883 18.9536C105.588 13.4731 100.529 9.62973 94.6145 8.87378L41.8899 2.13539C35.9656 1.37825 30.0867 3.83372 26.4775 8.57273L11.7652 27.8908M2 90.988L12.3931 115.817C14.6871 121.297 19.7454 125.14 25.6598 125.896L73.3877 132" stroke="#ACACAC" stroke-width="3" stroke-linecap="round" />
        </svg>
      ) : (
        <svg xmlns="http://www.w3.org/2000/svg" width="134" height="134" viewBox="0 0 134 134" fill="none">
          <path d="M73.6886 132L16.4435 105.484C10.9982 102.962 7.31607 97.7273 6.78646 91.7562L2.06564 38.5309C1.53519 32.5503 4.24812 26.7431 9.17793 23.3066L29.2738 9.29788M93.3966 2L118.066 13.4268C123.511 15.9489 127.193 21.1825 127.723 27.1531L132 75.3344" stroke="#ACACAC" stroke-width="3" stroke-linecap="round" />
        </svg>
      );
};
