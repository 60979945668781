export const headerItems = [
  // {
  //   id: 1,
  //   name: "Jesm",
  //   link: "#",
  // },
  {
    id: 2,
    name: "Что мы делаем",
    link: "it-services",
  },
  {
    id: 3,
    name: "Разработка",
    link: "dev-process",
  },
  {
    id: 4,
    name: "Партнеры",
    link: "our-partners",
  },
];
